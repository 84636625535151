import React, { useState } from "react";
import "./Projects.css";

import { motion } from "framer-motion";
import transition from "../../transition";

import BeyondImg from "../../Images/BeyondBoat.png";
import MailtoImg from "../../Images/MailtoGen.png";
import SaintDuJourImg from "../../Images/SaintDuJour.png";
import Footer from "../Footer/Footer";
import Navbar from "../Nav/Nav";

const projects = [
  {
    title: "Beyond Boat",
    description:
      "A dynamic website crafted with HTML, CSS, and JavaScript, inspired by a Figma template. Explore the seamless fusion of design and functionality in this project showcasing my web development skills.",
    image: BeyondImg,
    link: 'https://www.beyondboat.fr/',
  },
  {
    title: "Mailto Generator",
    description:
      "A dynamic website to help developpers for generating their mailto links",
    image: MailtoImg,
    link: 'https://mailtogen.tech/'
  },
  {
    title: "Saint Du Jour",
    description:
      "A website to display the saint of the day",
    image: SaintDuJourImg,
    link: 'https://saintdujour.org/'
  }
];

const Project = () => {
  const [currentProject, setCurrentProject] = useState(0);

  const handleNext = () => {
    setCurrentProject((prev) => (prev + 1) % projects.length);
  };

  const handlePrev = () => {
    setCurrentProject((prev) =>
      prev === 0 ? projects.length - 1 : prev - 1
    );
  };

  return (
    <>
      <Navbar uppercase={true}></Navbar>

      <div className="project-container">
        <div className="project-info">
          <motion.div
            className="project-img"
            key={projects[currentProject].image} // Key to trigger animation
            initial={{
              x: "100%",
              opacity: 0,
              clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",

            }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 1.5,
                ease: [0.83, 0, 0.17, 1],
              },
              clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
            }}
            exit={{
              x: "-100%",
              opacity: 0,
            }}
          >
            <motion.img
              src={projects[currentProject].image}
              alt={projects[currentProject].title}
              initial={{
                scale: 1.5,
              }}
              animate={{
                scale: 1,
                transition: {
                  duration: 0.5,
                  ease: [0, 0, 0.17, 1],
                },
              }}
            />
          </motion.div>
          <motion.div
            className="project-description"
            key={projects[currentProject].title} // Key to trigger animation
            initial={{
              y: "-100%",
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
                ease: [0.83, 0, 0.17, 1],
              },
            }}
            exit={{
              y: "100%",
              opacity: 0,
            }}
          >
            <h2>{projects[currentProject].title}</h2>
            <p>{projects[currentProject].description}</p>
            <a href={projects[currentProject].link} target="_blank" rel="noopener noreferrer" className="project-url">Visit the website</a>
          </motion.div>
        </div>

        <div className="navigation">
          <button onClick={handlePrev}>&lt;</button>
          <button onClick={handleNext}>&gt;</button>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default transition(Project);
